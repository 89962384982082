import { isAppPrerendering } from '@zyro-inc/site-modules/utils/prerenderingFlags';
import {
	facebookPixelScript,
	facebookPixelNoScript,
	googleAnalyticsScript,
	googleAnalyticsScriptAsync,
	googleTagManagerScript,
	googleTagManagerNoScript,
	hotjarScript,
	jivoChatScriptAsync,
} from '@zyro-inc/site-modules/utils/integrationScripts';
import { ELEMENT_DATA_ATTRIBUTE } from '@zyro-inc/site-modules/constants/siteModulesConstants';

export const getIntegrationElements = ({
	siteMeta,
	areCookiesAllowed,
}) => {
	if (isAppPrerendering) return [];

	const integrationElements = {};

	if (areCookiesAllowed && siteMeta.facebookPixel) {
		integrationElements['noscript-fb-pixel'] = {
			tagName: 'noscript',
			children: [
				{
					type: 'text',
					value: facebookPixelNoScript(siteMeta.facebookPixel),
				},
			],
		};
	}

	if (areCookiesAllowed && siteMeta.googleTagManager) {
		integrationElements['noscript-gtm'] = {
			tagName: 'noscript',
			children: [
				{
					type: 'text',
					value: googleTagManagerNoScript(siteMeta.googleTagManager),
				},
			],
		};
	}

	if (areCookiesAllowed && siteMeta.facebookPixel) {
		integrationElements['script-fb-pixel'] = {
			tagName: 'script',
			children: [
				{
					type: 'text',
					value: facebookPixelScript(siteMeta.facebookPixel),
				},
			],
		};
	}

	if (areCookiesAllowed && (siteMeta.googleTagManager || siteMeta.googleAdsIds?.length)) {
		integrationElements['script-gtm'] = {
			tagName: 'script',
			children: [
				{
					type: 'text',
					value: googleTagManagerScript({
						containerId: siteMeta.googleTagManager ? siteMeta.googleTagManager : siteMeta.googleAdsIds[0],
					}),
				},
			],
		};
	}

	if (areCookiesAllowed && (siteMeta.googleAnalytics || siteMeta.googleAdsIds?.length)) {
		integrationElements['script-google-analytics'] = {
			tagName: 'script',
			children: [
				{
					type: 'text',
					value: googleAnalyticsScript({
						containerId: siteMeta.googleAnalytics,
						googleAdsIds: siteMeta.googleAdsIds || [],
					}),
				},
			],
		};

		if (siteMeta.googleAnalytics) {
			integrationElements['script-google-analytics-async'] = {
				tagName: 'script',
				properties: {
					src: googleAnalyticsScriptAsync(siteMeta.googleAnalytics),
				},
			};
		}
	}

	if (areCookiesAllowed && siteMeta.hotjar) {
		integrationElements['script-hotjar'] = {
			tagName: 'script',
			children: [
				{
					type: 'text',
					value: hotjarScript(siteMeta.hotjar),
				},
			],
		};
	}

	if (siteMeta.jivoChat) {
		integrationElements['script-jivochat'] = {
			tagName: 'script',
			properties: {
				src: jivoChatScriptAsync(siteMeta.jivoChat),
			},
		};
	}

	return Object.entries(integrationElements)
		.map(([
			elementKey,
			{
				tagName,
				properties = {},
				children = [],
			},
		]) => ({
			type: 'element',
			tagName,
			properties: {
				...properties,
				[ELEMENT_DATA_ATTRIBUTE]: elementKey,
			},
			children,
		}));
};
