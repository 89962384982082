<template>
	<BlockEcommerceProduct
		v-bind="{
			blockId,
			blockStyle,
			blockButtonText,
			blockButtonStyle,
			blockButtonType,
			blockButtonBorderWidth,
			textColorVars,
			imageBorderRadius,
			navigationArrowsColor,
			navigationThumbnailArrowsColor,
			galleryPlacement,
			imageRatio,
			isQuantityPickerEnabled,
			quantifiedCartItemsList,
			isCheckoutLoading,
			legacyProductPages,
			isQuickPreview,
			currentPageType
		}"
		:is-mobile-view="isMobileView"
		:product-data="product"
		:can-add-to-cart="canAddToCart"
		:is-loading="isLoading"
		:translations="ecommerceTranslations"
		:is-cart-visible="isCartVisible"
		:is-eager="lcp.type === 'block-ecommerce-product' && lcp.id === blockId"
		:[DATA_ATTRIBUTE_ANIMATION_STATE]="animationState"
		:site-id="siteId"
		:shopping-cart-items="shoppingCartItems"
		:variants-quantity="variantsQuantity"
		@buy-button-click="handleBuyButtonClick"
		@image-click="handleImageClick"
		@open-cart="manageCartOpenState"
	/>
</template>

<script setup lang="ts">
import {
	DATA_ATTRIBUTE_ANIMATION_STATE,
	DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import BlockEcommerceProduct from '@zyro-inc/site-modules/components/blocks/ecommerce/BlockEcommerceProduct.vue';
import { useBlockEcommerceProduct } from '@zyro-inc/site-modules/components/blocks/ecommerce/useBlockEcommerceProduct';
import { getIsInIframe } from '@zyro-inc/site-modules/utils/getIsInIframe';

import { useEcommerceModal } from '@zyro-inc/site-modules/components/ecommerce/modals/useEcommerceModal';
import { useEcommerce } from '@zyro-inc/site-modules/components/ecommerce/useEcommerce';
import { DEFAULT_EMPTY_PRODUCT_VALUE } from '@zyro-inc/site-modules/constants/ecommerce';
import {
	EcommerceProductType,
	EcommerceProduct,
	EcommerceProductPage,
} from '@zyro-inc/site-modules/types';

import { useLightbox } from '@zyro-inc/site-modules/components/lightbox/useLightbox';
import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';

import {
	onMounted,
	onBeforeUnmount,
	ref,
	watch,
} from 'vue';
import { SiteBlock } from '@hostinger/builder-schema-validator';
import { getProductByIdOrSlug } from '@zyro-inc/site-modules/api/StoreApi';
import { isProductPage } from '@zyro-inc/site-modules/utils/ecommerce/siteModulesUtils';
import { updateProductJsonLd } from '@zyro-inc/site-modules/utils/getProductJsonLd';
import {
	getAddToCartEventPayload,
	googleTagManagerEvent,
} from '@zyro-inc/site-modules/utils/googleTagManager';

type Props = {
	blockId: string;
	data: SiteBlock;
	lcp: {
		type?: string;
		id?: string;
	};
	ecommerceTranslations: Record<string, string>;
	isQuickPreview: boolean;
	legacyProductPages: Array<EcommerceProductPage>;
	isCartVisible: boolean;
	currentPageType: string;
	isInPreviewMode: boolean;
	isMobileView: boolean;
	quickPreviewProductId?: string
	currentPreviewProductPageId?: string
};

const props = withDefaults(defineProps<Props>(), {
	blockId: '',
	lcp: () => ({}),
	ecommerceTranslations: () => ({}),
	isQuickPreview: false,
	legacyProductPages: () => [],
	isCartVisible: false,
	currentPageType: 'default',
	isInPreviewMode: false,
});

const {
	siteId,
	ecommerceStoreId,
	hasGoogleAdsApp,
} = useSiteGlobal();
const {
	quantifiedCartItemsList,
	canAddToCart,
	isShoppingCartOpen,
	shoppingCartItems,
	variantsQuantity,
	isCheckoutLoading,
	setShoppingCartOpen,
	setShoppingCartItems,
	setIsCheckoutLoading,
	setSelectedBookingProduct,
	updateVariantsQuantity,
} = useEcommerceGlobal();
const {
	openEcommerceModal,
	closeEcommerceModal,
} = useEcommerceModal();
const { initiateCheckout } = useEcommerce();
const { addImagesToLightbox } = useLightbox();
const {
	productId,
	blockStyle,
	blockButtonText,
	blockButtonStyle,
	blockButtonType,
	blockButtonBorderWidth,
	textColorVars,
	imageBorderRadius,
	navigationArrowsColor,
	navigationThumbnailArrowsColor,
	galleryPlacement,
	imageRatio,
	isQuantityPickerEnabled,
} = useBlockEcommerceProduct(props);

const isLoading = ref(true);
const animationState = ref<string | null>(null);
const product = ref<EcommerceProduct>(DEFAULT_EMPTY_PRODUCT_VALUE as EcommerceProduct);

const setAnimationState = () => {
	setTimeout(() => {
		animationState.value = DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE;
	}, 100);
};

const manageCartOpenState = () => {
	if (isShoppingCartOpen.value) {
		return;
	}

	setShoppingCartOpen(true);
};

const handleImageClick = (index: number) => {
	const productMedia = product.value.images || product.value.media || [];
	const aggregatedImages = productMedia.map((image) => ({
		alt: product.value.title,
		src: image.url,
	}));

	addImagesToLightbox(aggregatedImages, index);
};

const handleBuyButtonClick = async (value: EcommerceProduct[]) => {
	// Need to use await here so that all previous modals would get closed for others to open up
	await closeEcommerceModal();

	if (product.value.type.value === EcommerceProductType.BOOKING) {
		setSelectedBookingProduct(value[0]);
		openEcommerceModal('EcommerceBookingEventSelect');

		return;
	}

	if (getIsInIframe() || props.isInPreviewMode) {
		openEcommerceModal('EcommerceMessageButtonDisabled');

		return;
	}

	if (props.isCartVisible) {
		setShoppingCartItems([
			...shoppingCartItems.value,
			...value,
		]);

		if (hasGoogleAdsApp.value) {
			googleTagManagerEvent('add_to_cart', getAddToCartEventPayload(value[0]));
		}

		manageCartOpenState();
	} else {
		setIsCheckoutLoading(true);

		await initiateCheckout(value);

		setIsCheckoutLoading(false);
	}
};

const fetchProductData = async () => {
	const payload: {storeId: string; productId?: string, slug?: string} = {
		storeId: ecommerceStoreId.value,
	};

	isLoading.value = true;

	try {
		if (
			isProductPage(props.currentPageType)
			&& !props.isQuickPreview
			&& !props.isInPreviewMode
		) {
			payload.slug = window?.location?.pathname?.split('/')?.[1] || '';
		} else if (
			isProductPage(props.currentPageType)
			&& props.isInPreviewMode
			&& props.currentPreviewProductPageId
		) {
			payload.productId = props.currentPreviewProductPageId;
		} else if (productId.value !== -1) {
			payload.productId = productId.value;
		} else {
			// Do not fetch anything if product id is missing
			return;
		}

		const productData = await getProductByIdOrSlug(payload);

		await updateVariantsQuantity([productData]);

		if (productData) {
			product.value = productData;
		}
	} catch (error) {
		console.error(error);
	} finally {
		isLoading.value = false;
	}
};

const initiateProduct = async () => {
	setAnimationState();

	await fetchProductData();
};

onMounted(() => {
	initiateProduct();
});

onBeforeUnmount(() => {
	closeEcommerceModal();
});

if (isProductPage(props.currentPageType)) {
	watch(() => props.currentPreviewProductPageId, (newValue, oldValue) => {
		if (newValue !== oldValue) {
			initiateProduct();
		}
	});
	watch(product, () => {
		if (product.value) {
			updateProductJsonLd(product.value, variantsQuantity.value);
		}
	});
}
</script>
